/**
 * Zybach.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ChemigationInjectionValveDto } from '../model/chemigation-injection-valve-dto';
import { ChemigationInspectionFailureReasonDto } from '../model/chemigation-inspection-failure-reason-dto';
import { ChemigationInspectionSimpleDto } from '../model/chemigation-inspection-simple-dto';
import { ChemigationInspectionStatusDto } from '../model/chemigation-inspection-status-dto';
import { ChemigationInspectionTypeDto } from '../model/chemigation-inspection-type-dto';
import { ChemigationInspectionUpsertDto } from '../model/chemigation-inspection-upsert-dto';
import { ChemigationInterlockTypeDto } from '../model/chemigation-interlock-type-dto';
import { ChemigationLowPressureValveDto } from '../model/chemigation-low-pressure-valve-dto';
import { ChemigationMainlineCheckValveDto } from '../model/chemigation-mainline-check-valve-dto';
import { CropTypeDto } from '../model/crop-type-dto';
import { TillageDto } from '../model/tillage-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class ChemigationInspectionService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param chemigationInspectionID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chemigationInspectionsChemigationInspectionIDDelete(chemigationInspectionID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public chemigationInspectionsChemigationInspectionIDDelete(chemigationInspectionID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public chemigationInspectionsChemigationInspectionIDDelete(chemigationInspectionID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public chemigationInspectionsChemigationInspectionIDDelete(chemigationInspectionID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chemigationInspectionID === null || chemigationInspectionID === undefined) {
            throw new Error('Required parameter chemigationInspectionID was null or undefined when calling chemigationInspectionsChemigationInspectionIDDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/chemigationInspections/${encodeURIComponent(String(chemigationInspectionID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param chemigationInspectionID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chemigationInspectionsChemigationInspectionIDGet(chemigationInspectionID: number, observe?: 'body', reportProgress?: boolean): Observable<ChemigationInspectionSimpleDto>;
    public chemigationInspectionsChemigationInspectionIDGet(chemigationInspectionID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChemigationInspectionSimpleDto>>;
    public chemigationInspectionsChemigationInspectionIDGet(chemigationInspectionID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChemigationInspectionSimpleDto>>;
    public chemigationInspectionsChemigationInspectionIDGet(chemigationInspectionID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chemigationInspectionID === null || chemigationInspectionID === undefined) {
            throw new Error('Required parameter chemigationInspectionID was null or undefined when calling chemigationInspectionsChemigationInspectionIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ChemigationInspectionSimpleDto>(`${this.basePath}/chemigationInspections/${encodeURIComponent(String(chemigationInspectionID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param chemigationInspectionID 
     * @param chemigationInspectionUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chemigationInspectionsChemigationInspectionIDPut(chemigationInspectionID: number, chemigationInspectionUpsertDto?: ChemigationInspectionUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public chemigationInspectionsChemigationInspectionIDPut(chemigationInspectionID: number, chemigationInspectionUpsertDto?: ChemigationInspectionUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public chemigationInspectionsChemigationInspectionIDPut(chemigationInspectionID: number, chemigationInspectionUpsertDto?: ChemigationInspectionUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public chemigationInspectionsChemigationInspectionIDPut(chemigationInspectionID: number, chemigationInspectionUpsertDto?: ChemigationInspectionUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chemigationInspectionID === null || chemigationInspectionID === undefined) {
            throw new Error('Required parameter chemigationInspectionID was null or undefined when calling chemigationInspectionsChemigationInspectionIDPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/chemigationInspections/${encodeURIComponent(String(chemigationInspectionID))}`,
            chemigationInspectionUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chemigationInspectionsFailureReasonsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ChemigationInspectionFailureReasonDto>>;
    public chemigationInspectionsFailureReasonsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChemigationInspectionFailureReasonDto>>>;
    public chemigationInspectionsFailureReasonsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChemigationInspectionFailureReasonDto>>>;
    public chemigationInspectionsFailureReasonsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ChemigationInspectionFailureReasonDto>>(`${this.basePath}/chemigationInspections/failureReasons`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chemigationInspectionsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ChemigationInspectionSimpleDto>>;
    public chemigationInspectionsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChemigationInspectionSimpleDto>>>;
    public chemigationInspectionsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChemigationInspectionSimpleDto>>>;
    public chemigationInspectionsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ChemigationInspectionSimpleDto>>(`${this.basePath}/chemigationInspections`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chemigationInspectionsInjectionValvesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ChemigationInjectionValveDto>>;
    public chemigationInspectionsInjectionValvesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChemigationInjectionValveDto>>>;
    public chemigationInspectionsInjectionValvesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChemigationInjectionValveDto>>>;
    public chemigationInspectionsInjectionValvesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ChemigationInjectionValveDto>>(`${this.basePath}/chemigationInspections/injectionValves`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chemigationInspectionsInspectionStatusesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ChemigationInspectionStatusDto>>;
    public chemigationInspectionsInspectionStatusesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChemigationInspectionStatusDto>>>;
    public chemigationInspectionsInspectionStatusesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChemigationInspectionStatusDto>>>;
    public chemigationInspectionsInspectionStatusesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ChemigationInspectionStatusDto>>(`${this.basePath}/chemigationInspections/inspectionStatuses`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chemigationInspectionsInspectionTypesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ChemigationInspectionTypeDto>>;
    public chemigationInspectionsInspectionTypesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChemigationInspectionTypeDto>>>;
    public chemigationInspectionsInspectionTypesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChemigationInspectionTypeDto>>>;
    public chemigationInspectionsInspectionTypesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ChemigationInspectionTypeDto>>(`${this.basePath}/chemigationInspections/inspectionTypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chemigationInspectionsInterlockTypesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ChemigationInterlockTypeDto>>;
    public chemigationInspectionsInterlockTypesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChemigationInterlockTypeDto>>>;
    public chemigationInspectionsInterlockTypesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChemigationInterlockTypeDto>>>;
    public chemigationInspectionsInterlockTypesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ChemigationInterlockTypeDto>>(`${this.basePath}/chemigationInspections/interlockTypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chemigationInspectionsLowPressureValvesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ChemigationLowPressureValveDto>>;
    public chemigationInspectionsLowPressureValvesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChemigationLowPressureValveDto>>>;
    public chemigationInspectionsLowPressureValvesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChemigationLowPressureValveDto>>>;
    public chemigationInspectionsLowPressureValvesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ChemigationLowPressureValveDto>>(`${this.basePath}/chemigationInspections/lowPressureValves`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chemigationInspectionsMainlineCheckValvesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ChemigationMainlineCheckValveDto>>;
    public chemigationInspectionsMainlineCheckValvesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChemigationMainlineCheckValveDto>>>;
    public chemigationInspectionsMainlineCheckValvesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChemigationMainlineCheckValveDto>>>;
    public chemigationInspectionsMainlineCheckValvesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ChemigationMainlineCheckValveDto>>(`${this.basePath}/chemigationInspections/mainlineCheckValves`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param chemigationPermitAnnualRecordID 
     * @param chemigationInspectionUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chemigationPermitsAnnualRecordsChemigationPermitAnnualRecordIDCreateInspectionPost(chemigationPermitAnnualRecordID: number, chemigationInspectionUpsertDto?: ChemigationInspectionUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<ChemigationInspectionSimpleDto>;
    public chemigationPermitsAnnualRecordsChemigationPermitAnnualRecordIDCreateInspectionPost(chemigationPermitAnnualRecordID: number, chemigationInspectionUpsertDto?: ChemigationInspectionUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChemigationInspectionSimpleDto>>;
    public chemigationPermitsAnnualRecordsChemigationPermitAnnualRecordIDCreateInspectionPost(chemigationPermitAnnualRecordID: number, chemigationInspectionUpsertDto?: ChemigationInspectionUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChemigationInspectionSimpleDto>>;
    public chemigationPermitsAnnualRecordsChemigationPermitAnnualRecordIDCreateInspectionPost(chemigationPermitAnnualRecordID: number, chemigationInspectionUpsertDto?: ChemigationInspectionUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chemigationPermitAnnualRecordID === null || chemigationPermitAnnualRecordID === undefined) {
            throw new Error('Required parameter chemigationPermitAnnualRecordID was null or undefined when calling chemigationPermitsAnnualRecordsChemigationPermitAnnualRecordIDCreateInspectionPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ChemigationInspectionSimpleDto>(`${this.basePath}/chemigationPermits/annualRecords/${encodeURIComponent(String(chemigationPermitAnnualRecordID))}/createInspection`,
            chemigationInspectionUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param chemigationPermitNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chemigationPermitsChemigationPermitNumberLatestChemigationInspectionGet(chemigationPermitNumber: number, observe?: 'body', reportProgress?: boolean): Observable<ChemigationInspectionSimpleDto>;
    public chemigationPermitsChemigationPermitNumberLatestChemigationInspectionGet(chemigationPermitNumber: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChemigationInspectionSimpleDto>>;
    public chemigationPermitsChemigationPermitNumberLatestChemigationInspectionGet(chemigationPermitNumber: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChemigationInspectionSimpleDto>>;
    public chemigationPermitsChemigationPermitNumberLatestChemigationInspectionGet(chemigationPermitNumber: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chemigationPermitNumber === null || chemigationPermitNumber === undefined) {
            throw new Error('Required parameter chemigationPermitNumber was null or undefined when calling chemigationPermitsChemigationPermitNumberLatestChemigationInspectionGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ChemigationInspectionSimpleDto>(`${this.basePath}/chemigationPermits/${encodeURIComponent(String(chemigationPermitNumber))}/latestChemigationInspection`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cropTypesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<CropTypeDto>>;
    public cropTypesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CropTypeDto>>>;
    public cropTypesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CropTypeDto>>>;
    public cropTypesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CropTypeDto>>(`${this.basePath}/cropTypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public tillageTypesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<TillageDto>>;
    public tillageTypesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TillageDto>>>;
    public tillageTypesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TillageDto>>>;
    public tillageTypesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TillageDto>>(`${this.basePath}/tillageTypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
