<nav aria-label="breadcrumb">
    <ol class="breadcrumb p-3">
        <li class="breadcrumb-item" aria-current="page">
            <a routerLink="/labels-and-definitions" *ngIf="currentUserIsAdmin()">Labels and Definitions</a>
            <span *ngIf="!currentUserIsAdmin()">Labels and Definitions</span>
        </li>
        <li class="breadcrumb-item active" aria-current="page" *ngIf="fieldDefinition">
            {{fieldDefinition.FieldDefinitionType.FieldDefinitionTypeDisplayName}}
        </li>
    </ol>
</nav>
<div class="container mt-sm-4" *ngIf="fieldDefinition">
    <app-alert-display></app-alert-display>
    <h2 class="d-inline-block">
        Edit Definition for Label: {{fieldDefinition.FieldDefinitionType.FieldDefinitionTypeDisplayName}} </h2>
    <div class="row mt-4">
        <div class="col-12">
            <editor #tinyMceEditor
                [init]="tinyMceConfig"
                [(ngModel)]="fieldDefinition.FieldDefinitionValue">
            </editor>
            <div style="float:right;">
                <button type="button" class="btn btn-zybach mt-1 me-1 mb-1" (click)="saveDefinition()" [disabled]="isLoadingSubmit">Save</button>
                <a routerLink="/labels-and-definitions" class="btn btn-secondary mt-1 mb-1">Cancel</a>
            </div>
        </div>
    </div>
</div>