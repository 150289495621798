<nav aria-label="breadcrumb">
    <ol class="breadcrumb p-3">
        <li class="breadcrumb-item active" aria-current="page">Disclaimer</li>
    </ol>
</nav>
<div class="container">
    <app-alert-display></app-alert-display>
    <h2 class="d-inline-block">Disclaimer</h2>
    <div class="row">
        <div class="col">
            <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
        </div>
    </div>
    <div class="row" *ngIf="checkDisclaimerAcknowledged()">
        <div class="col">
            <button class="btn btn-zybach btn-sm me-3 float-end"
                (click)="setDisclaimerAcknowledged()">Acknowledge</button>
        </div>
    </div>
</div>