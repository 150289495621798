<div class="container navCaretMargin">
    <app-alert-display></app-alert-display>
    <div class="row">
        <div class="col-12">
            <h2 class="d-inline-block">Sensor Anomaly Reports</h2>
            <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col">
            <button class="btn btn-secondary btn-md float-end" style="cursor: pointer;" (click)="exportToCsv()"
            ngbTooltip="Download as CSV" [placement]="['bottom', 'right', 'left', 'auto']"><i class="fas fa-download"></i></button>
            <fresca-clear-grid-filters-button class="float-end" [grid]="sensorAnomaliesGrid" classList="me-2">
            </fresca-clear-grid-filters-button>
        </div>
    </div>
    <ag-grid-angular #sensorAnomaliesGrid style="width: 100%; height: 500px;" class="ag-theme-balham mt-2"
        [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [pagination]="true" [paginationPageSize]="100"
        (cellClicked)="onCellClicked($event)">
    </ag-grid-angular>
</div>
<ng-template #deleteSensorAnomalyModal let-modal>
    <div class="modal-header bg-secondary">
        <h5 class="modal-title" id="deleteSensorAnomalyModalTitle">Warning</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            You are about to delete the sensor anomaly reported for sensor <b>{{sensorAnomalyToDelete.Sensor.SensorName}}</b> between 
            {{sensorAnomalyToDelete.StartDate | date: 'shortDate':'UTC'}} and {{sensorAnomalyToDelete.EndDate | date: 'shortDate':'UTC'}}.<br><br>
            Are you sure you wish to proceed?
        </p>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="deleteSensorAnomaly()">
                <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
                Delete
            </button>
            <button type="button" class="btn btn-secondary" (click)="modal.close('Cancel click')">Cancel</button>
        </div>
    </div>
</ng-template>