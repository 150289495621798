<div class="container navCaretMargin">
    <app-alert-display></app-alert-display>
    <div class="row">
        <div class="col-12">
            <h2 class="d-inline-block">Seasonal Water Level Inspections</h2>
            <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
        </div>
    </div>
    <div class="row">
        <div class="container col-12">
            <div class="row my-2">
                <div class="col">
                    <a class="btn btn-zybach btn-md me-3 float-end" routerLink="/water-level-inspections/new"
                    ngbTooltip="Create New Inspection" [placement]="['bottom', 'right', 'left', 'auto']"><span class="fas fa-plus"></span> New Inspection </a>
                    <button class="btn btn-secondary btn-md float-end me-3" style="cursor: pointer;" (click)="exportToCsv()"
                    ngbTooltip="Download as CSV" [placement]="['bottom', 'right', 'left', 'auto']"><i class="fas fa-download"></i></button>
                    <fresca-clear-grid-filters-button class="me-3 float-end" [grid]="waterLevelInspectionsGrid">
                    </fresca-clear-grid-filters-button>
                </div>
            </div>
            <div class="table-responsive">
                <ag-grid-angular #waterLevelInspectionsGrid style="width: 100%; height: 500px;" 
                    class="ag-theme-balham"
                    [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
                    [pagination]="true" [paginationPageSize]="100" [suppressMenuHide]="true">
                </ag-grid-angular>
            </div>
        </div>
    </div>
</div>