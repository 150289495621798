<div class="row mb-2">
    <div class="col-lg-3"   >
        <div class="row mb-3 align-items-end">
            <div class="col-auto pe-0">
                <label class="control-label d-inline-block">Filter By</label>
            </div>
            <div class="col">
                <select (change)="onMapFilterChange($event.target.value)" name="fitlerOptionSelect" class="form-control d-inline-block">
                    <option [value]="true">Last Message Age</option>
                    <option [value]="false">Last Voltage Reading</option>
                </select>
            </div>
        </div>
    </div>
    <div class="col-lg-5"> 
        <ng-select [(ngModel)]="selectedFilterOptions" placeholder="No map filters selected" [multiple]="true"
            [closeOnSelect]="false" (change)="onSelectedFilterOptionsChange()" [selectableGroup]="true"
            [selectableGroupAsModel]="false" groupBy="group_name" [items]="filterOptionsDropdownList" bindLabel="item_text"
            bindValue="item_id">
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> Select All
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> {{item.item_text}}
            </ng-template>
        </ng-select>
    </div>
    <div class="col-lg-4">
        <div class="input-group">
            <input type="text" name="mapSearchQuery" class="form-control" placeholder="Search map by address..." [(ngModel)]="mapSearchQuery" (keyup.enter)="mapSearch()"/>
            <div class="input-group-append">
                <button class="btn btn-zybach" type="button" (click)="mapSearch()" >Search</button>
            </div>
        </div>
    </div>
</div>

<div id="{{mapID}}" [style.height]="mapHeight"></div>

<div class="d-flex justify-content-end">
    <div class="legend">
        <ng-container *ngIf="filterMapByLastMessageAge else lastVoltageReadingLegend">
            <div>
                <img style='height: 20px; width: 12px;  margin-right: 10px; display: inline-block' src='/assets/main/0b2c7a.png'/> 0-24 Hours<br/>
                <img style='height: 20px; width: 12px;  margin-right: 10px; display: inline-block' src='/assets/main/c2523c.png'/> >24 Hours<br/>
            </div>
            <div>
                <img style='height: 20px; width: 12px;  margin-right: 10px; display: inline-block' src='/assets/main/noDataSourceMarker.png'/>No Data<br/>
                <img style='height: 20px; width: 12px;  margin-right: 10px; display: inline-block' src='/assets/main/continuityMeterMarker.png'/> Has Active Support Ticket<br/>
            </div>
        </ng-container>
    </div>
</div>

<ng-template #lastVoltageReadingLegend>
    <div>
        <img style='height: 20px; width: 12px;  margin-right: 10px; display: inline-block' src='/assets/main/0b2c7a.png'/> 2700-4000 mV <br/>
        <img style='height: 20px; width: 12px;  margin-right: 10px; display: inline-block' src='/assets/main/flowMeterMarker.png'/> &#62;4000 mV <br/>
        <img style='height: 20px; width: 12px;  margin-right: 10px; display: inline-block' src='/assets/main/fcf003.png'/> 2500-2700 mV <br/>
    </div>
    <div>
        <img style='height: 20px; width: 12px;  margin-right: 10px; display: inline-block' src='/assets/main/c2523c.png'/> &#60;2500 mV <br/>
        <img style='height: 20px; width: 12px;  margin-right: 10px; display: inline-block' src='/assets/main/noDataSourceMarker.png'/>No Data<br/>
    </div>
</ng-template>



