<nav aria-label="breadcrumb">
    <ol class="breadcrumb p-3">
        <li class="breadcrumb-item">
            <a routerLink="/chemigation-permits">Chemigation Permits</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">New Chemigation Permit</li>
    </ol>
</nav>
<div class="container">
    <app-alert-display></app-alert-display>
    <div class="row mt-2">
        <div class="col-12">
            <h2 class="d-inline-block">New Chemigation Permit</h2>
        </div>

    </div>
    <div class="row mt-2">
        <div class="col-12">
            <p>Add a new Chemigation Permit using the form below</p>
        </div>
    </div>
    <form (ngSubmit)="onSubmit(newChemigationPermitForm)" #newChemigationPermitForm="ngForm">
        <div class="row align-items-center">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row col-12">
                            <div class="row col-6 mt-4">

                                <div class="col-6 control-label text-end mb-2">
                                    <label class="required"><strong>Permit Status: </strong></label>
                                </div>
                                <div class="col-6 text-start mb-2">
                                    <ng-select [(ngModel)]="model.ChemigationPermitStatusID"
                                        [items]="permitStatuses" bindLabel="ChemigationPermitStatusDisplayName" bindValue="ChemigationPermitStatusID" 
                                        placeholder="Choose One" name="ChemigationPermitStatusID" required>
                                    </ng-select>
                                </div>

                                <div class="col-6 control-label text-end mb-2">
                                    <label><strong>Permit Number: </strong></label>
                                </div>
                                <div class="col-6 text-start mb-2">
                                    Will be autogenerated
                                </div>

                            </div>
    
                            <div class="row col-6 mt-4">
                                <div class="col-6 control-label text-end mb-2">
                                    <label><strong>
                                        <field-definition [fieldDefinitionType]="'WellRegistrationNumber'">
                                        </field-definition>:
                                    </strong></label>
                                </div>
                                <div class="col-6 text-start mb-2">
                                    <input type="text" class="form-control" name="WellRegistrationID" [(ngModel)]="model.WellRegistrationID" [ngbTypeahead]="searchApi" placeholder="Search by Reg #" />
                                    <small class="form-text text-muted font-italic" *ngIf="searchFailed">Suggestions could not be loaded.</small>
                                </div>
 
                                <div class="col-6 control-label text-end mb-2">
                                    <label class="required"><strong>County: </strong></label>
                                </div>
                                <div class="col-6 text-start mb-2">
                                    <ng-select [(ngModel)]="model.CountyID"
                                        [items]="counties" bindLabel="CountyDisplayName" bindValue="CountyID" 
                                        placeholder="Choose One" name="CountyID" required>
                                    </ng-select>
                                </div>
                            </div>

                        </div>

                        <hr class="w-90" />

                        <zybach-chemigation-permit-annual-record-upsert #annualRecordForm
                            [(model)]="model.ChemigationPermitAnnualRecord">
                        </zybach-chemigation-permit-annual-record-upsert>

                        <hr class="w-90" />

                        <zybach-chemigation-permit-applicators-editor #applicatorsForm
                            [(model)]="model.ChemigationPermitAnnualRecord.Applicators">
                        </zybach-chemigation-permit-applicators-editor>

                    </div>
                </div>

                <div class="row col-12 mt-4">
                    <div class="col-sm text-start">
                        <i class="fas fa-bolt"></i> Required field
                    </div>
                    <div class="col-sm text-end">
                        <button type="submit" class="btn btn-zybach">
                            <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
                            Save
                        </button>
                        <a class="btn btn-secondary ms-1" routerLink="/chemigation-permits">Cancel</a>
                    </div>
                </div>
            
            </div>
        </div>
    </form>
</div>