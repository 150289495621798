<form #inspectionUpsertForm="ngForm">

    <div class="row col-12 mt-4">
        <div class="row col-6">
            
            <div class="col-6 control-label text-end mb-2">
                <label><strong>Date Inspected: </strong></label>
            </div>
            <div class="col-6 text-start mb-2 form-group">
                <div class="input-group">
                    <input class="form-control" name="dp1"
                    placeholder="yyyy-mm-dd"
                    [(ngModel)]="inspection.InspectionDate" ngbDatepicker #d1="ngbDatepicker" />
                    <div class="input-group-append">
                        <button class="btn btn-zybach input-group-append" (click)="d1.toggle()" type="button"><span class="far fa-calendar me-2 col-1"> </span></button>
                    </div>
                </div>
            </div>
            
            <div class="col-6 control-label text-end mb-2">
                <label><strong>Inspection Type: </strong></label>
            </div>
            <div class="col-6 text-start mb-2">
                <ng-select [(ngModel)]="inspection.ChemigationInspectionTypeID"
                    [items]="chemigationInspectionTypes" bindLabel="ChemigationInspectionTypeDisplayName" bindValue="ChemigationInspectionTypeID" 
                    placeholder="Choose One" name="ChemigationInspectionTypeID">
                </ng-select>
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label><strong>Inspector: </strong></label>
            </div>
            <div class="col-6 text-start mb-2">
                <ng-select [(ngModel)]="inspection.InspectorUserID"
                    [items]="users" bindLabel="FullNameLastFirst" bindValue="UserID" 
                    placeholder="Choose One" name="InspectorUserID">
                </ng-select>
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label><strong>Status: </strong></label>
            </div>
            <div class="col-6 text-start mb-2">
                <ng-select [(ngModel)]="inspection.ChemigationInspectionStatusID"
                    [items]="chemigationInspectionStatuses" bindLabel="ChemigationInspectionStatusDisplayName" bindValue="ChemigationInspectionStatusID" 
                    placeholder="Choose One" name="ChemigationInspectionStatusID">
                </ng-select>
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label><strong>Failure Reason: </strong></label>
            </div>
            <div class="col-6 text-start mb-2">
                <ng-select [(ngModel)]="inspection.ChemigationInspectionFailureReasonID"
                    [items]="chemigationInspectionFailureReasons" bindLabel="ChemigationInspectionFailureReasonDisplayName" bindValue="ChemigationInspectionFailureReasonID" 
                    placeholder="Choose One" name="ChemigationInspectionFailureReasonID">
                </ng-select>
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label><strong>Inspection Notes: </strong></label>
            </div>
            <div class="col-6 text-start mb-2">
                <textarea class="form-control" name="InspectionNotes"
                    placeholder="Notes" [(ngModel)]="inspection.InspectionNotes">
                </textarea>
            </div>

        </div>

        <div class="row col-6">

            <div class="col-6 control-label text-end mb-2">
                <label><strong>Mainline Check Valve: </strong></label>
            </div>
            <div class="col-6 text-start mb-2">
                <ng-select [(ngModel)]="inspection.ChemigationMainlineCheckValveID"
                    [items]="chemigationMainlineCheckValves" bindLabel="ChemigationMainlineCheckValveDisplayName" bindValue="ChemigationMainlineCheckValveID" 
                    placeholder="Choose One" name="ChemigationMainlineCheckValveID">
                </ng-select>
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label><strong>Low Pressure Valve: </strong></label>
            </div>
            <div class="col-6 text-start mb-2">
                <ng-select [(ngModel)]="inspection.ChemigationLowPressureValveID"
                    [items]="chemigationLowPressureValves" bindLabel="ChemigationLowPressureValveDisplayName" bindValue="ChemigationLowPressureValveID" 
                    placeholder="Choose One" name="ChemigationLowPressureValveID">
                </ng-select>
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label><strong>Chemigation Injection Valve: </strong></label>
            </div>
            <div class="col-6 text-start mb-2">
                <ng-select [(ngModel)]="inspection.ChemigationInjectionValveID"
                    [items]="chemigationInjectionValves" bindLabel="ChemigationInjectionValveDisplayName" bindValue="ChemigationInjectionValveID" 
                    placeholder="Choose One" name="ChemigationInjectionValveID">
                </ng-select>
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label><strong>Chemigation Interlock Type: </strong></label>
            </div>
            <div class="col-6 text-start mb-2">
                <ng-select [(ngModel)]="inspection.ChemigationInterlockTypeID"
                    [items]="chemigationInterlockTypes" bindLabel="ChemigationInterlockTypeDisplayName" bindValue="ChemigationInterlockTypeID" 
                    placeholder="Choose One" name="ChemigationInterlockTypeID">
                </ng-select>
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label><strong>Vacuum Relief Valve?: </strong></label>
            </div>
            <div class="col-6 text-start mb-2 form-group">
                <div class="input-group">
                    <input type="radio" id="hasVacuumReliefValve" class="me-2" name="HasVacuumReliefValve"
                        [value]="true" [(ngModel)]="inspection.HasVacuumReliefValve">
                    <label for="hasVacuumReliefValve" class="me-4">Yes</label>
                    <input type="radio" id="notHasVacuumReliefValve" class="me-2" name="HasVacuumReliefValve"
                        [value]="false" [(ngModel)]="inspection.HasVacuumReliefValve">
                    <label for="notHasVacuumReliefValve">No</label>
                </div>
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label><strong>Inspection Port?: </strong></label>
            </div>
            <div class="col-6 text-start mb-2 form-group">
                <div class="input-group">
                    <input type="radio" id="hasInspectionPort" class="me-2" name="HasInspectionPort"
                        [value]="true" [(ngModel)]="inspection.HasInspectionPort" />
                    <label for="hasInspectionPort" class="me-4">Yes</label>
                    <input type="radio" id="notHasInspectionPort" class="me-2" name="HasInspectionPort"
                        [value]="false" [(ngModel)]="inspection.HasInspectionPort" />
                    <label for="notHasInspectionPort">No</label>
                </div>
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label><strong>Tillage: </strong></label>
            </div>
            <div class="col-6 text-start mb-2">
                <ng-select [(ngModel)]="inspection.TillageID"
                    [items]="tillages" bindLabel="TillageDisplayName" bindValue="TillageID" 
                    placeholder="Choose One" name="TillageID">
                </ng-select>
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label><strong>Crop Type: </strong></label>
            </div>
            <div class="col-6 text-start mb-2">
                <ng-select [(ngModel)]="inspection.CropTypeID"
                    [items]="cropTypes" bindLabel="CropTypeDisplayName" bindValue="CropTypeID" 
                    placeholder="Choose One" name="CropTypeID">
                </ng-select>
            </div>

        </div>
    </div>

</form>