<div class="container navCaretMargin">
    <app-alert-display></app-alert-display>
    <div class="row">
        <div class="col-12 mb-2">
            <h2 class="d-inline-block">Flow Test Report</h2>
            <custom-rich-text [customRichTextTypeID]="richTextTypeID" />
        </div>
    </div>
    <div class="row">
        <div class="container col-12">
            <div class="row ms-2 mb-2">
                <div class="col">
                    <a
                        class="btn btn-secondary btn-md float-end me-2"
                        style="cursor: pointer"
                        (click)="exportToCsv()"
                        ngbTooltip="Download Report as CSV"
                        [placement]="['bottom', 'right', 'left', 'auto']"
                    >
                        <i class="fas fa-download"></i>
                    </a>
                    <fresca-clear-grid-filters-button
                        class="float-end me-2"
                        [grid]="flowTestReportGrid"
                    />
                </div>
            </div>
            <div class="table-responsive">
                <ag-grid-angular
                    #flowTestReportGrid
                    style="width: 100%; height: 800px"
                    class="ag-theme-balham"
                    [rowData]="rowData"
                    [columnDefs]="columnDefs"
                    [pagination]="true"
                    [suppressMenuHide]="true"
                    [paginationPageSize]="100"
                    (gridReady)="onGridReady($event)"
                />
            </div>
        </div>
    </div>
</div>
