<h4>Certified Chemigation Applicators</h4>
<form #applicatorsForm="ngForm">
    <div class="row col-12" *ngIf="model.length > 0">
        <div class="col-3 control-label text-start mb-2">
            <label class="required"><strong>Name: </strong></label>
        </div>

        <div class="col-1 control-label text-start mb-2">
            <label class="required"><strong>Certification Number: </strong></label>
        </div>

        <div class="col-2 control-label text-start mb-2">
            <label class="required"><strong>Expiration: </strong></label>
        </div>

        <div class="col-2 control-label text-start mb-2">
            <label><strong>Home Phone: </strong></label>
        </div>
        
        <div class="col-2 control-label text-start mb-2">
            <label><strong>Mobile Phone: </strong></label>
        </div>
    </div>
    <div class="row col-12" *ngFor="let chemicalPermitAnnualRecordApplicator of model; let index = index">
        <div class="col-3 text-start mb-2">
            <input type="text" class="form-control" name="ApplicatorName-{{index}}" required
            placeholder="Last, First" [(ngModel)]="chemicalPermitAnnualRecordApplicator.ApplicatorName" />
        </div>

        <div class="col-1 text-start mb-2">
            <input type="number" step="1" class="form-control" name="CertificationNumber-{{index}}" required
                [(ngModel)]="chemicalPermitAnnualRecordApplicator.CertificationNumber" />
        </div>

        <div class="col-2 text-start mb-2">
            <ng-select [(ngModel)]="chemicalPermitAnnualRecordApplicator.ExpirationYear"
                [items]="expirationYears" 
                placeholder="Choose One" name="ExpirationYear-{{index}}" required>
            </ng-select>
        </div>

        <div class="col-2 text-start mb-2">
            <input type="text" class="form-control" name="HomePhone-{{index}}"
            placeholder="###-###-####" [(ngModel)]="chemicalPermitAnnualRecordApplicator.HomePhone" />
        </div>

        <div class="col-2 text-start mb-2">
            <input type="text" class="form-control" name="MobilePhone-{{index}}"
            placeholder="###-###-####" [(ngModel)]="chemicalPermitAnnualRecordApplicator.MobilePhone" />
        </div>

        <div class="col-2 text-start mb-2">
            <button type="button" class="btn btn-zybach" (click)="deleteRow(chemicalPermitAnnualRecordApplicator)">
                <span class="fa fa-trash"></span>
                Delete Row
            </button>
        </div>
    </div>
</form>
<div class="row ms-3 mt-2 col-2">
    <button type="button" class="btn btn-zybach" (click)="addRow()">
        <span class="fa fa-plus"></span>
        Add New Applicator
    </button>
</div>