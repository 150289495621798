<div class="row col-12 mt-4">
    <div class="col-12">
        <div class="card">
            <div class="card-header" *ngIf="waterLevelInspections">
                Water Level Sensor Data Readings
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <zybach-sensor-chart [(sensorChartData)]="wellGroup.WellPressureSensorChartData"></zybach-sensor-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 mt-4">    
        <div class="card">
            <div class="card-header" *ngIf="waterLevelInspections">
                Seasonal Water Level Inspections
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <div *ngIf="hasWaterLevelChartData; else noWaterLevelSpecDiv" [id]="waterLevelChartID" style="width:100%; min-height: 340px;"><span class="fa fa-spinner loading-spinner"></span> Loading</div>
                        <ng-template #noWaterLevelSpecDiv><em>No Water Level Chart data to show</em></ng-template>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <a *ngIf="wellGroup.PrimaryWell" class="btn btn-zybach btn-md float-end m-2" routerLink="/wells/{{wellGroup.PrimaryWell.WellID}}/new-water-level-inspection"
                            ngbTooltip="New Water Level Inspection" [placement]="['bottom', 'right', 'left', 'auto']"><span class="fas fa-plus"></span></a>

                        <button class="btn btn-secondary btn-md float-end m-2" style="cursor: pointer;" (click)="exportToCsv()"
                            ngbTooltip="Download as CSV" [placement]="['bottom', 'right', 'left', 'auto']"><i class="fas fa-download"></i></button>

                        <fresca-clear-grid-filters-button class="m-2 float-end" [grid]="waterLevelInspectionsGrid"></fresca-clear-grid-filters-button>
                    </div> 
                </div>

                <div class="table-responsive">
                    <ag-grid-angular #waterLevelInspectionsGrid style="width: 100%; height: 300px;" class="ag-theme-balham"
                        [rowData]="waterLevelInspections" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
                        [pagination]="true" [paginationPageSize]="10" [suppressMenuHide]="true">
                    </ag-grid-angular>
                </div>
            </div>
        </div>
    </div>
</div>