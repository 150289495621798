<div *ngIf="isLoading" class="text-center">
    <span class="fa fa-spinner loading-spinner"></span>
</div>

<div *ngIf="!isLoading" class="d-inline">
    <ng-template #popTitle>
        <div class="row">
            <div class="col-auto me-auto">
                <div class="mt-1">{{fieldDefinition.FieldDefinitionType.FieldDefinitionTypeDisplayName}}</div>
            </div>
            <div class="col-auto" *ngIf="!isEditing">
                <div class="row">
                    <div class="col-sm-2 ps-0 pe-0">
                        <button type="button" class="btn btn-link" (click)="enterEdit()">
                            <i class="fa fa-pencil-square-o" attr.aria-label="Edit the definition for {{fieldDefinition.FieldDefinitionType.FieldDefinitionTypeDisplayName}}">
                            </i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #popContent>
        <ng-container *ngIf="!isEditing">
            <div *ngIf="showEditButton()" style="position: relative; min-height: 30px;">
                <div *ngIf="!emptyContent" [innerHtml]="fieldDefinition.FieldDefinitionValue"></div>
                <div *ngIf="emptyContent" class="hoverEmptySpace"></div>
            </div>
            <div *ngIf="!showEditButton()" [innerHtml]="fieldDefinition.FieldDefinitionValue"></div>
        </ng-container>
        <div *ngIf="isEditing">
            <editor #tinyMceEditor [init]="tinyMceConfig"
                [(ngModel)]="editedContent">
            </editor>
            
            <div style="float:right;">
                <button type="button" class="btn btn-sm btn-zybach mt-1 me-1 mb-1" (click)="saveEdit()">Save</button>
                <button type="button" class="btn btn-sm btn-secondary mt-1 mb-1" (click)="cancelEdit(p)">Cancel</button>
            </div>
        </div>
    </ng-template>
    <ng-container *ngIf="showEditButton()">
        <div class="d-inline-block">
            <span style="overflow:hidden; text-overflow:ellipsis">
                {{labelTextWithoutLastWord}}
                <span style="white-space:nowrap">
                    {{labelTextLastWord}}
                    <i class="fas fa-question-circle small" (mouseenter)="notEditingMouseEnter()" (mouseleave)="notEditingMouseLeave()" style="cursor: help;" [placement]="['bottom','right','left','auto']" #p="ngbPopover" [ngbPopover]="popContent"
                    [popoverTitle]="popTitle" triggers="manual" [autoClose]="false" popoverClass="popover-override"
                    container="body">
                </i>
                </span>
                
            </span>
        </div>
    </ng-container>
    <ng-container *ngIf="!showEditButton()">
        <span>
            {{labelTextWithoutLastWord}}
            <span style="white-space:nowrap">
                {{labelTextLastWord}}
                <i class="fas fa-question-circle small" style="cursor: help;" [ngbPopover]="popContent"
                popoverClass="popover-override"
                [popoverTitle]="fieldDefinition.FieldDefinitionType.FieldDefinitionTypeDisplayName"
                triggers="mouseenter:mouseleave" container="body">
            </i>
            </span>
            
        </span>
    </ng-container>
</div>