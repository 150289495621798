<nav aria-label="breadcrumb" *ngIf="wellRegistrationID">
    <ol class="breadcrumb p-3">
        <li class="breadcrumb-item">
            <a routerLink="/well-map">Wells</a>
        </li>
        <li class="breadcrumb-item">
            <a routerLink="/wells/{{wellID}}">{{wellRegistrationID}}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
            Edit Contact Details for Well {{wellRegistrationID}}
        </li>
    </ol>
</nav>

<div class="container" *ngIf="wellContactInfo">
    <app-alert-display></app-alert-display>
    <div class="row mt-2">
        <div class="col-12">
            <h2 class="d-inline-block">Edit Well Contact Details</h2>
        </div>

    </div>

    <div class="row mt-2">
        <div class="col">
            <div class="card">
                <div class="card-header">
                    Contact Information
                </div>
                <div class="card-body">
                    <form (ngSubmit)="onSubmit(editWellContactForm)" #editWellContactForm="ngForm" class="container">
                    
                        <div class="row col-12 mt-2">

                            <div class="row col-12 mb-3">
                                <div class="row col-6">
                                    <div class="col-12 text-center mb-2">
                                        <h5 class="d-inline-block">Owner Contact Details</h5>
                                    </div>

                                    <div class="col-6 control-label text-end mb-2">
                                        <label><strong>Name or Company: </strong></label>
                                    </div>
                                    <div class="col-6 text-start mb-2">
                                        <input type="text" class="form-control" name="OwnerName"
                                        [(ngModel)]="wellContactInfo.OwnerName" placeholder="Last, First" />
                                    </div>

                                    <div class="col-6 control-label text-end mb-2">
                                        <label><strong>Address: </strong></label>
                                    </div>
                                    <div class="col-6 text-start mb-2">
                                        <input type="text" class="form-control" name="OwnerAddress"
                                        [(ngModel)]="wellContactInfo.OwnerAddress" placeholder="Street Address" />
                                    </div>

                                    <div class="col-6 control-label text-end mb-2">
                                        <label><strong>City: </strong></label>
                                    </div>
                                    <div class="col-6 text-start mb-2">
                                        <input type="text" class="form-control" name="OwnerCity"
                                        [(ngModel)]="wellContactInfo.OwnerCity" placeholder="City" />
                                    </div>

                                    <div class="col-6 control-label text-end mb-2">
                                        <label><strong>State: </strong></label>
                                    </div>
                                    <div class="col-6 text-start mb-2">
                                        <ng-select [(ngModel)]="wellContactInfo.OwnerState"
                                            [items]="states | keyvalue" bindLabel="key" bindValue="value" 
                                            placeholder="Choose One" name="OwnerState">
                                        </ng-select>
                                    </div>

                                    <div class="col-6 control-label text-end mb-2">
                                        <label><strong>Zip: </strong></label>
                                    </div>
                                    <div class="col-6 text-start mb-2">
                                        <input type="text" class="form-control" name="OwnerZipCode" [dropSpecialCharacters]="false" mask="00000-0000"
                                            placeholder="Zip Code" [(ngModel)]="wellContactInfo.OwnerZipCode" />
                                    </div>

                                </div>
                                
                                <div class="row col-6">
                                    
                                    <div class="col-12 text-center mb-2">
                                        <h5 class="d-inline-block">Additional Contact Details</h5>
                                    </div>
                                    
                                    <div class="col-6 control-label text-end mb-2">
                                        <label><strong>Name: </strong></label>
                                    </div>
                                    <div class="col-6 text-start mb-2">
                                        <input type="text" class="form-control" name="AdditionalContactName"
                                        [(ngModel)]="wellContactInfo.AdditionalContactName" placeholder="Last, First" />
                                    </div>
                                    
                                    <div class="col-6 control-label text-end mb-2">
                                        <label><strong>Address: </strong></label>
                                    </div>
                                    <div class="col-6 text-start mb-2">
                                        <input type="text" class="form-control" name="AdditionalContactAddress"
                                        [(ngModel)]="wellContactInfo.AdditionalContactAddress" placeholder="Street Address" />
                                    </div>
                                    
                                    <div class="col-6 control-label text-end mb-2">
                                        <label><strong>City: </strong></label>
                                    </div>
                                    <div class="col-6 text-start mb-2">
                                        <input type="text" class="form-control" name="AdditionalContactCity"
                                        [(ngModel)]="wellContactInfo.AdditionalContactCity" placeholder="City" />
                                    </div>
                                    
                                    <div class="col-6 control-label text-end mb-2">
                                        <label><strong>State: </strong></label>
                                    </div>
                                    <div class="col-6 text-start mb-2">
                                        <ng-select [(ngModel)]="wellContactInfo.AdditionalContactState"
                                        [items]="states | keyvalue" bindLabel="key" bindValue="value" 
                                        placeholder="Choose One" name="AdditionalContactState">
                                        </ng-select>
                                    </div>

                                    <div class="col-6 control-label text-end mb-2">
                                        <label><strong>Zip: </strong></label>
                                    </div>
                                    <div class="col-6 text-start mb-2">
                                        <input type="text" class="form-control" name="AdditionalContactZipCode" [dropSpecialCharacters]="false" mask="00000-0000"
                                        placeholder="Zip Code" [(ngModel)]="wellContactInfo.AdditionalContactZipCode" />
                                    </div>
                                </div>
                                
                            </div>

                            <hr class="row col-12 w-80 mt-2" />

                            <div class="row col-12 mt-4">
                                <div class="row col-6">
                                    <div class="col-6 control-label text-end mb-2">
                                        <label><strong>Township-Range-Section: </strong></label>
                                    </div>
                                    <div class="col-6 text-start mb-2">
                                        <input type="text" class="form-control" name="TownshipRangeSection"
                                        [(ngModel)]="wellContactInfo.TownshipRangeSection" placeholder="Legal Description" />
                                    </div>
                    
                                    <div class="col-6 control-label text-end mb-2">
                                        <label><strong>County: </strong></label>
                                    </div>
                                    <div class="col-6 text-start mb-2">
                                        <ng-select [(ngModel)]="wellContactInfo.CountyID"
                                            [items]="counties" bindLabel="CountyDisplayName" bindValue="CountyID" 
                                            placeholder="Choose One" name="CountyID">
                                        </ng-select>
                                    </div>
                                </div>

                                <div class="row col-6">
                                    <div class="col-6 control-label text-end mb-2">
                                        <label><strong>Well Nickname: </strong></label>
                                    </div>
                                    <div class="col-6 text-start mb-2">
                                        <input type="text" class="form-control" name="WellNickname"
                                        placeholder="Nickname" [(ngModel)]="wellContactInfo.WellNickname" />
                                    </div>
            
                                    <div class="col-6 control-label text-end mb-2">
                                        <label><strong>Notes: </strong></label>
                                    </div>
                                    <div class="col-6 text-start mb-2">
                                        <textarea class="form-control" name="Notes"
                                            placeholder="Notes" [(ngModel)]="wellContactInfo.Notes">
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 text-end mt-3 me-3">
                            <button type="submit" class="btn btn-zybach me-1">
                                <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
                                Save
                            </button>
                            <a class="btn btn-secondary ms-1"
                                routerLink="/wells/{{wellID}}">
                                Cancel
                            </a>
                        </div>
                
                    </form>
                </div>
            </div>
        </div>
    </div>

</div>