<nav aria-label="breadcrumb">
  <ol class="breadcrumb p-3">
      <li class="breadcrumb-item active" aria-current="page">Users</li>
  </ol>
</nav>

<div class="container">
  <app-alert-display></app-alert-display>

  <div *ngIf="users && unassignedUsers.length" class="mb-4">
    <h2 class="d-inline-block">Unassigned Users</h2>
    <p class="lead" *ngIf="users && unassignedUsers.length">
      This is a list of all users who are awaiting account configuration. Assign the user a role and billing account(s). If the account was created in error, set their Role to “Disabled” to remove them from this list.
    </p>

    <div class="row mb-2">
      <div class="col-auto ms-auto">
        <fresca-clear-grid-filters-button class="float-end" [grid]="unassignedUsersGrid"></fresca-clear-grid-filters-button>
      </div>
    </div>
    <div class="table-responsive">
      <ag-grid-angular #unassignedUsersGrid style="width: 100%; height: 300px;" class="ag-theme-balham" [rowData]="unassignedUsers"
        [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [pagination]="true" [paginationPageSize]="100" (gridReady)="onGridReady($event)">
      </ag-grid-angular>
    </div>
  </div>

  <h2 class="d-inline-block\">Users</h2>
  <p class="lead">
    A list of users is shown in the grid below.
  </p>

  <div class="row mb-2">
    <div class="col-auto ms-auto">
      <fresca-clear-grid-filters-button class="me-2" [grid]="usersGrid"></fresca-clear-grid-filters-button>
      <button class="btn btn-secondary btn-md me-2" style="cursor: pointer;" (click)="exportToCsv()"
        ngbTooltip="Download as CSV" [placement]="['bottom', 'right', 'left', 'auto']"><i class="fas fa-download"></i>
      </button>
      <a class="btn btn-zybach btn-md" routerLink="/invite-user">Invite User</a>
    </div>
  </div>

  <div class="table-responsive">
    <ag-grid-angular #usersGrid style="width: 100%; height: 800px;" class="ag-theme-balham" [rowData]="users"
      [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [pagination]="true" [paginationPageSize]="100" (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
</div>