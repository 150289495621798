<nav aria-label="breadcrumb" *ngIf="waterQualityInspection">
    <ol class="breadcrumb p-3">
        <li class="breadcrumb-item">
            <a routerLink="/water-quality-inspections">Water Quality Inspections</a>
        </li>
        <li class="breadcrumb-item" aria-current="page">
            <a routerLink="/wells/{{waterQualityInspection.Well.WellID}}">Well #{{waterQualityInspection.Well.WellRegistrationID}}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
            {{waterQualityInspection.InspectionDate | date:'MM/dd/yyyy'}}
        </li>
    </ol>
</nav>
<app-alert-display></app-alert-display>
<div class="row container" *ngIf="waterQualityInspection">
    <div class="col-12">
        <h2 class="d-inline-block">{{waterQualityInspection.InspectionDate | date:'MM/dd/yyyy'}}</h2>
    </div>
    <div class="row container" *ngIf="waterQualityInspection">
        <div class="col-12 col-sm-6 label"><strong>
            <field-definition [fieldDefinitionType]="'WellRegistrationNumber'">
            </field-definition>: 
        </strong></div>
        <div class="col-12 col-sm-6"><a *ngIf="waterQualityInspection.Well" routerLink="/wells/{{waterQualityInspection.Well.WellID}}">{{waterQualityInspection.Well.WellRegistrationID}}</a></div>
        <div class="col-12 col-sm-6 label"><strong>Inspected By: </strong></div>
        <div class="col-12 col-sm-6"><a *ngIf="waterQualityInspection.Inspector" routerLink="/users/{{waterQualityInspection.Inspector.UserID}}">{{waterQualityInspection.Inspector.FullNameLastFirst}}</a></div>
        <div class="col-12 col-sm-6 label"><strong>Inspection Type: </strong></div>
        <div class="col-12 col-sm-6">{{waterQualityInspection.WaterQualityInspectionTypeName}}</div>
        <div class="col-12 col-sm-6 label"><strong>Nickname: </strong></div>
        <div class="col-12 col-sm-6">{{waterQualityInspection.InspectionNickname}}</div>
    </div>
</div>
<div class="row mt-4 container" *ngIf="waterQualityInspection">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-body">
                <div class="row col-12">
                    <div class="row col-6">
                        <div class="row col-12">
                            <div class="col-6 text-end">
                                <p><strong>Temperature: </strong></p>
                            </div>
                            <div class="col-6 text-start">
                                <p>{{waterQualityInspection.Temperature}}
                                </p>
                            </div>
                        </div>

                        <div class="row col-12">
                            <div class="col-6 text-end">
                                <p><strong>PH: </strong></p>
                            </div>
                            <div class="col-6 text-start">
                                <p>{{waterQualityInspection.PH}}
                                </p>
                            </div>
                        </div>

                        <div class="row col-12">
                            <div class="col-6 text-end">
                                <p><strong>Conductivity: </strong></p>
                            </div>
                            <div class="col-6 text-start">
                                <p>{{waterQualityInspection.Conductivity}}
                                </p>
                            </div>
                        </div>

                        <div class="row col-12">
                            <div class="col-6 text-end">
                                <p><strong>Field Alkalinity: </strong></p>
                            </div>
                            <div class="col-6 text-start">
                                <p>{{waterQualityInspection.FieldAlkilinity}}
                                </p>
                            </div>
                        </div>

                        <div class="row col-12">
                            <div class="col-6 text-end">
                                <p><strong>Field Nitrates: </strong></p>
                            </div>
                            <div class="col-6 text-start">
                                <p>{{waterQualityInspection.FieldNitrates}}
                                </p>
                            </div>
                        </div>

                        <div class="row col-12">
                            <div class="col-6 text-end">
                                <p><strong>Lab Nitrates: </strong></p>
                            </div>
                            <div class="col-6 text-start">
                                <p>{{waterQualityInspection.LabNitrates}}
                                </p>
                            </div>
                        </div>

                        <div class="row col-12">
                            <div class="col-6 text-end">
                                <p><strong>Salinity: </strong></p>
                            </div>
                            <div class="col-6 text-start">
                                <p>{{waterQualityInspection.Salinity}}
                                </p>
                            </div>
                        </div>

                        <div class="row col-12">
                            <div class="col-6 text-end">
                                <p><strong>Crop: </strong></p>
                            </div>
                            <div class="col-6 text-start">
                                <p>
                                    {{waterQualityInspection.CropTypeName}}
                                </p>
                            </div>
                        </div>

                        <div class="row col-12">
                            <div class="col-6 text-end">
                                <p><strong>Notes: </strong></p>
                            </div>
                            <div class="col-6 text-start">
                                <p>
                                    {{waterQualityInspection.InspectionNotes}}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="row col-6">

                        <div class="row col-12">
                            <div class="col-6 text-end">
                                <p><strong>MV: </strong></p>
                            </div>
                            <div class="col-6 text-start">
                                <p>
                                    {{waterQualityInspection.MV}}
                                </p>
                            </div>
                        </div>

                        <div class="row col-12">
                            <div class="col-6 text-end">
                                <p><strong>Na: </strong></p>
                            </div>
                            <div class="col-6 text-start">
                                <p>
                                    {{waterQualityInspection.Sodium}}
                                </p>
                            </div>
                        </div>

                        <div class="row col-12">
                            <div class="col-6 text-end">
                                <p><strong>Ca: </strong></p>
                            </div>
                            <div class="col-6 text-start">
                                <p>
                                    {{waterQualityInspection.Calcium}}
                                </p>
                            </div>
                        </div>

                        <div class="row col-12">
                            <div class="col-6 text-end">
                                <p><strong>Mg: </strong></p>
                            </div>
                            <div class="col-6 text-start">
                                <p>
                                    {{waterQualityInspection.Magnesium}}
                                </p>
                            </div>
                        </div>

                        <div class="row col-12">
                            <div class="col-6 text-end">
                                <p><strong>K: </strong></p>
                            </div>
                            <div class="col-6 text-start">
                                <p>
                                    {{waterQualityInspection.Potassium}}
                                </p>
                            </div>
                        </div>

                        <div class="row col-12">
                            <div class="col-6 text-end">
                                <p><strong>HCO<sub>3</sub><sup>-</sup>: </strong></p>
                            </div>
                            <div class="col-6 text-start">
                                <p>
                                    {{waterQualityInspection.HydrogenCarbonate}}
                                </p>
                            </div>
                        </div>

                        <div class="row col-12">
                            <div class="col-6 text-end">
                                <p><strong>CaCO<sub>3</sub>: </strong></p>
                            </div>
                            <div class="col-6 text-start">
                                <p>
                                    {{waterQualityInspection.CalciumCarbonate}}
                                </p>
                            </div>
                        </div>

                        <div class="row col-12">
                            <div class="col-6 text-end">
                                <p><strong>SO<sub>4</sub><sup>2-</sup>: </strong></p>
                            </div>
                            <div class="col-6 text-start">
                                <p>
                                    {{waterQualityInspection.Sulfate}}
                                </p>
                            </div>
                        </div>

                        <div class="row col-12">
                            <div class="col-6 text-end">
                                <p><strong>Cl: </strong></p>
                            </div>
                            <div class="col-6 text-start">
                                <p>
                                    {{waterQualityInspection.Chloride}}
                                </p>
                            </div>
                        </div>

                        <div class="row col-12">
                            <div class="col-6 text-end">
                                <p><strong>SiO<sub>2</sub>: </strong></p>
                            </div>
                            <div class="col-6 text-start">
                                <p>
                                    {{waterQualityInspection.SiliconDioxide}}
                                </p>
                            </div>
                        </div>

                        <div class="row col-12">
                            <div class="col-6 text-end">
                                <p><strong>Pre Water Level: </strong></p>
                            </div>
                            <div class="col-6 text-start">
                                <p>
                                    {{waterQualityInspection.PreWaterLevel}}
                                </p>
                            </div>
                        </div>

                        <div class="row col-12">
                            <div class="col-6 text-end">
                                <p><strong>Post Water Level: </strong></p>
                            </div>
                            <div class="col-6 text-start">
                                <p>
                                    {{waterQualityInspection.PostWaterLevel}}
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="row float-end mt-3 col-6">
            <a class="btn btn-zybach btn-md me-3 col-4"
                routerLink="/water-quality-inspections/{{waterQualityInspection.WaterQualityInspectionID}}/edit">
                <span class="fas fa-edit"></span> Edit Inspection
            </a>
            
            <button type="button" class="btn btn-zybach btn-md me-3 col-4" [disabled]="isPerformingAction" (click)="launchDeleteInspectionModal(waterQualityInspection.WaterQualityInspectionID)">
                <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
                <span class="fas fa-trash"></span> Delete Inspection
            </button>
        </div>
    </div>
</div>

<ng-template #deleteInspectionModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Confirm Delete</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Are you sure you want to delete this inspection? This action cannot be undone
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-zybach btn-md" [disabled]="isPerformingAction" (click)="deleteInspectionByID()">
            <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
            Confirm
        </button>
        <button type="button" class="btn btn-secondary btn-md" (click)="modal.close('Cancel click')"
            [disabled]="isPerformingAction">
            Cancel
        </button>
    </div>
</ng-template>