<div class="container navCaretMargin">
    <app-alert-display></app-alert-display>
    <div class="row">
        <div class="col-12 mb-2">
            <h2 class="d-inline-block">Chemigation Permit Reports</h2>
            <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
        </div>
    </div>
    <div class="row">
        <div class="container col-12">
            <div class="col-4 float-start ms-2">
                <div class="row align-items-center">
                    <span class="far fa-calendar me-2 col-1"> </span>
                    <div class="col-6">
                        <zybach-water-year-select [(selectedYear)]="yearToDisplay"
                            (selectedYearChange)="updateAnnualData()" [(allYearsSelected)]="allYearsSelected"
                            (allYearsSelectedChange)="updateAnnualData()"
                            >
                        </zybach-water-year-select>
                    </div>
                </div>
            </div>
            <div class="row ms-2 mb-2">
                <div class="col">
                    <button (click)="generateReport()" [disabled]="isLoadingSubmit" class="btn btn-zybach btn-md float-end"
                        ngbTooltip="Generate Chemigation Permit Annual Renewal Forms" [placement]="['bottom', 'right', 'left', 'auto']">
                        <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
                        Generate Renewal Forms
                    </button>
                    <select name="ReportTemplateID" class="form-control me-3 float-end mb-2" style="width:auto; min-width: 100px;" 
                        *ngIf="modelHasMultipleTemplates()" [(ngModel)]="selectedReportTemplateID" required>
                        <option value="undefined" disabled>Select a Report Template</option>
                        <option *ngFor="let reportTemplate of reportTemplates" [value]="reportTemplate.ReportTemplateID">
                            {{reportTemplate.DisplayName}}
                        </option>
                    </select>
                    <a class="btn btn-secondary btn-md float-end me-2" style="cursor: pointer;" (click)="exportToCsv()"
                    ngbTooltip="Download Report as CSV" [placement]="['bottom', 'right', 'left', 'auto']"><i class="fas fa-download"></i></a>
                    <fresca-clear-grid-filters-button class="float-end me-2" [grid]="chemigationPermitReportGrid">
                    </fresca-clear-grid-filters-button>
                </div>
            </div>
            <div class="table-responsive">
                <ag-grid-angular #chemigationPermitReportGrid style="width: 100%; height: 800px;" class="ag-theme-balham" [rowData]="rowData" [rowSelection]="'multiple'"
                    [columnDefs]="columnDefs" [pagination]="true" [suppressMenuHide]="true" [paginationPageSize]="100" [pinnedBottomRowData]="pinnedBottomRowData"
                    (filterChanged)="onFilterChanged($event)" (gridReady)="onGridReady($event)">
                </ag-grid-angular>
                <div><span>{{getSelectedRows().length}} of {{getFilteredRowsCount()}} Selected</span></div>
            </div>
        </div>
    </div>
</div>
