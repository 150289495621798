<nav aria-label="breadcrumb">
  <ol class="breadcrumb p-3">
    <li class="breadcrumb-item">
      <a routerLink="/users">Users</a>
    </li>
    <li class="breadcrumb-item">
      <a routerLink="/users/{{user?.UserID}}">
        {{user?.FullName || (user?.FirstName + ' ' + user?.LastName)}}
      </a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">
      Edit Basics
    </li>
  </ol>
</nav>
<div class="container mt-sm-4" *ngIf="model">
    <h2 class="d-inline-block">{{user?.FullName ||  ((user?.FirstName || user?.LastName) ? (user?.FirstName + ' ' + user?.LastName) : null)}}</h2>
    <app-alert-display></app-alert-display>
  <div class="col-sm-12">
    <form (ngSubmit)="onSubmit(editUserForm)" #editUserForm="ngForm">
      <div class="form-group mt-4">
        <div class="col-sm-2 control-label">
          <label class="required">Role</label>
        </div>
        <div class="col-sm-10">
          <ng-select name="role" [(ngModel)]="model.RoleID" (ngOnChanges)="checkReceiveSupportEmails()"
            [items]="roles" bindLabel="RoleDisplayName" bindValue="RoleID" 
            placeholder="Choose a Role" >
          </ng-select>
        </div>
      </div>

      <ng-container *ngIf="selectedRoleIsAdmin()">
        <div class="form-group mt-4">
          <div class="col-sm-5 control-label">
            <label class="required">Should Receive System Communications?</label>
          </div>
          <div class="col-sm-10">
            <label class="ms-2"><input type="radio" name="ReceiveSupportEmails"
              [(ngModel)]="model.ReceiveSupportEmails" [value]="true"> Yes</label>
            <label class="ms-2"><input type="radio" name="ReceiveSupportEmails"
              [(ngModel)]="model.ReceiveSupportEmails" [value]="false"> No</label>
          </div>
        </div>

        <div class="form-group mt-4">
          <div class="col-sm-5 control-label">
            <label class="required">Performs Chemigation Inspections?</label>
          </div>
          <div class="col-sm-10">
            <label class="ms-2"><input type="radio" name="PerformsChemigationInspections"
              [(ngModel)]="model.PerformsChemigationInspections" [value]="true"> Yes</label>
            <label class="ms-2"><input type="radio" name="PerformsChemigationInspections"
              [(ngModel)]="model.PerformsChemigationInspections" [value]="false"> No</label>
          </div>
        </div>
      </ng-container>

      <div class="row">
        <div class="col-sm">
          <i class="fas fa-bolt"></i> Required field
        </div>
        <div class="col-sm text-end">
          <button type="submit" class="btn btn-zybach" [disabled]="!editUserForm.form.valid || isLoadingSubmit">
            <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
            Submit
          </button>
          <a class="btn btn-secondary ms-1" routerLink="/users/{{user?.UserID}}">Cancel</a>
        </div>
      </div>
    </form>
  </div>
</div>