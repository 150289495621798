/**
 * Zybach.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { PaigeWirelessPulseDto } from '../model/paige-wireless-pulse-dto';
import { SensorChartDataDto } from '../model/sensor-chart-data-dto';
import { SensorSimpleDto } from '../model/sensor-simple-dto';
import { SupportTicketSimpleDto } from '../model/support-ticket-simple-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class SensorService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<SensorSimpleDto>>;
    public sensorsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SensorSimpleDto>>>;
    public sensorsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SensorSimpleDto>>>;
    public sensorsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SensorSimpleDto>>(`${this.basePath}/sensors`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param sensorID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorsSensorIDChartDataGet(sensorID: number, observe?: 'body', reportProgress?: boolean): Observable<SensorChartDataDto>;
    public sensorsSensorIDChartDataGet(sensorID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SensorChartDataDto>>;
    public sensorsSensorIDChartDataGet(sensorID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SensorChartDataDto>>;
    public sensorsSensorIDChartDataGet(sensorID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sensorID === null || sensorID === undefined) {
            throw new Error('Required parameter sensorID was null or undefined when calling sensorsSensorIDChartDataGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SensorChartDataDto>(`${this.basePath}/sensors/${encodeURIComponent(String(sensorID))}/chartData`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param sensorID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorsSensorIDGet(sensorID: number, observe?: 'body', reportProgress?: boolean): Observable<SensorSimpleDto>;
    public sensorsSensorIDGet(sensorID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SensorSimpleDto>>;
    public sensorsSensorIDGet(sensorID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SensorSimpleDto>>;
    public sensorsSensorIDGet(sensorID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sensorID === null || sensorID === undefined) {
            throw new Error('Required parameter sensorID was null or undefined when calling sensorsSensorIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SensorSimpleDto>(`${this.basePath}/sensors/${encodeURIComponent(String(sensorID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param sensorID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorsSensorIDOpenSupportTicketsGet(sensorID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<SupportTicketSimpleDto>>;
    public sensorsSensorIDOpenSupportTicketsGet(sensorID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SupportTicketSimpleDto>>>;
    public sensorsSensorIDOpenSupportTicketsGet(sensorID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SupportTicketSimpleDto>>>;
    public sensorsSensorIDOpenSupportTicketsGet(sensorID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sensorID === null || sensorID === undefined) {
            throw new Error('Required parameter sensorID was null or undefined when calling sensorsSensorIDOpenSupportTicketsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SupportTicketSimpleDto>>(`${this.basePath}/sensors/${encodeURIComponent(String(sensorID))}/openSupportTickets`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param sensorID 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorsSensorIDSnoozePut(sensorID: number, body?: boolean, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public sensorsSensorIDSnoozePut(sensorID: number, body?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public sensorsSensorIDSnoozePut(sensorID: number, body?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public sensorsSensorIDSnoozePut(sensorID: number, body?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sensorID === null || sensorID === undefined) {
            throw new Error('Required parameter sensorID was null or undefined when calling sensorsSensorIDSnoozePut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<string>(`${this.basePath}/sensors/${encodeURIComponent(String(sensorID))}/snooze`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param sensorName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorsSensorNamePulseGet(sensorName: string, observe?: 'body', reportProgress?: boolean): Observable<PaigeWirelessPulseDto>;
    public sensorsSensorNamePulseGet(sensorName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaigeWirelessPulseDto>>;
    public sensorsSensorNamePulseGet(sensorName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaigeWirelessPulseDto>>;
    public sensorsSensorNamePulseGet(sensorName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sensorName === null || sensorName === undefined) {
            throw new Error('Required parameter sensorName was null or undefined when calling sensorsSensorNamePulseGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PaigeWirelessPulseDto>(`${this.basePath}/sensors/${encodeURIComponent(String(sensorName))}/pulse`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param sensorName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sensorsSensorNameSearchGet(sensorName: string, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public sensorsSensorNameSearchGet(sensorName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public sensorsSensorNameSearchGet(sensorName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public sensorsSensorNameSearchGet(sensorName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sensorName === null || sensorName === undefined) {
            throw new Error('Required parameter sensorName was null or undefined when calling sensorsSensorNameSearchGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<string>>(`${this.basePath}/sensors/${encodeURIComponent(String(sensorName))}/search`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
