<nav aria-label="breadcrumb">
    <ol class="breadcrumb p-3">
        <li class="breadcrumb-item">
            <a routerLink="/chemigation-permits">Chemigation Permits</a>
        </li>
        <li class="breadcrumb-item" aria-current="page">
            <a routerLink="/chemigation-permits/{{chemigationPermit?.ChemigationPermitNumber}}">
                Chemigation Permit #{{chemigationPermit?.ChemigationPermitNumberDisplay}}
            </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
            Edit Annual Record
        </li>
    </ol>
</nav>
<div class="container">
    <app-alert-display></app-alert-display>
    <div class="row mt-2">
        <div class="col-12">
            <h2 class="d-inline-block">Edit {{model?.RecordYear}} Record for #{{chemigationPermit?.ChemigationPermitNumberDisplay}}</h2>
        </div>

    </div>
    <div class="row mt-2">
        <div class="col-12">
            <p>Edit the annual record for {{model?.RecordYear}} for the selected Chemigation Permit using the form below
            </p>
        </div>
    </div>
    <form (ngSubmit)="onSubmit(editChemigationPermitAnnualRecordForm)" #editChemigationPermitAnnualRecordForm="ngForm">
        <div class="row align-items-center">
            <div class="col-12">
                <div class="card" *ngIf="model">
                    <div class="card-body">
                        <div class="row col-12">
                            <div class="row col-6 mt-4">
                                <div class="col-6 control-label text-end mb-2">
                                    <label><strong>
                                        <field-definition [fieldDefinitionType]="'WellRegistrationNumber'">
                                        </field-definition>: 
                                    </strong></label>
                                </div>
                                <div class="col-6 text-start mb-2">
                                    <a *ngIf="chemigationPermit.Well" routerLink="/wells/{{chemigationPermit.Well.WellID}}">{{chemigationPermit.Well.WellRegistrationID}}</a>
                                    <em *ngIf="!chemigationPermit.Well" class="text-muted">N/A</em>
                                </div>

                                <div class="col-6 control-label text-end mb-2">
                                    <label><strong>Permit Status: </strong></label>
                                </div>
                                <div class="col-6 text-start mb-2">
                                    {{chemigationPermit.ChemigationPermitStatus.ChemigationPermitStatusDisplayName}}
                                </div>

                                <div class="col-6 control-label text-end mb-2">
                                    <label><strong>Chemigation Permit Number: </strong></label>
                                </div>
                                <div class="col-6 text-start mb-2">
                                    {{chemigationPermit.ChemigationPermitNumberDisplay}}
                                </div>

                            </div>

                            <div class="row col-6 mt-4">
                                <div class="col-6 control-label text-end mb-2">
                                    <label><strong>Renewal Year: </strong></label>
                                </div>
                                <div class="col-6 text-start mb-2">
                                    {{model.RecordYear}}
                                </div>

                                <div class="col-6 control-label text-end mb-2">
                                    <label><strong>County: </strong></label>
                                </div>
                                <div class="col-6 text-start mb-2">
                                    {{chemigationPermit.County.CountyDisplayName}}
                                </div>

                            </div>

                        </div>

                        <hr class="w-90" />

                        <zybach-chemigation-permit-annual-record-upsert #annualRecordForm
                            [(model)]="model">
                        </zybach-chemigation-permit-annual-record-upsert>

                        <hr class="w-90" />

                        <zybach-chemigation-permit-applicators-editor #applicatorsForm
                            [(model)]="model.Applicators">
                        </zybach-chemigation-permit-applicators-editor>

                        <hr class="w-90" />

                        <zybach-chemigation-permit-chemical-formulations-editor #chemicalFormulationsForm
                            [(model)]="model.ChemicalFormulations">
                        </zybach-chemigation-permit-chemical-formulations-editor>

                    </div>
                </div>

                <div class="row col-12 mt-4">
                    <div class="col-sm text-start">
                        <i class="fas fa-bolt"></i> Required field
                    </div>
                    <div class="col-sm text-end">
                        <button type="submit" class="btn btn-zybach">
                            <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
                            Save
                        </button>
                        <a class="btn btn-secondary ms-1"
                            routerLink="/chemigation-permits/{{chemigationPermit?.ChemigationPermitNumber}}">
                            Cancel
                        </a>
                    </div>
                </div>

            </div>
        </div>
    </form>
</div>