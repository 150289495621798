<nav aria-label="breadcrumb" *ngIf="inspection">
    <ol class="breadcrumb p-3">
        <li class="breadcrumb-item">
            <a routerLink="/chemigation-permits">Chemigation Permits</a>
        </li>
        <li class="breadcrumb-item">
            <a routerLink="/chemigation-permits/{{chemigationPermitNumber}}">
                Chemigation Permit #{{chemigationPermitNumberDisplay}}
            </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
            Edit Inspection
        </li>
    </ol>
</nav>


<div class="container" *ngIf="inspection">
    <app-alert-display></app-alert-display>
    <div class="row mt-2">
        <div class="col-12">
            <h2 class="d-inline-block">Edit Inspection</h2>
        </div>

    </div>

    <div class="row mt-2">
        <div class="col-12">
            <p>Edit chemigation inspection record for Permit #{{chemigationPermitNumberDisplay}} using the form below
            </p>
        </div>
    </div>

    <form (ngSubmit)="onSubmit(updateChemigationInspectionForm)" #updateChemigationInspectionForm="ngForm" class="container">

        <zybach-chemigation-inspection-upsert #inspectionUpsertForm
        [(inspection)]="inspection">
        </zybach-chemigation-inspection-upsert >

        <div class="col-12 text-end mt-3 me-3">
            <button type="submit" class="btn btn-zybach me-1">
                <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
                Save
            </button>
            <a class="btn btn-secondary ms-1"
                routerLink="/chemigation-permits/{{chemigationPermitNumber}}">
                Cancel
            </a>
        </div>

    </form>

</div>
