<nav aria-label="breadcrumb">
    <ol class="breadcrumb p-3">
        <li class="breadcrumb-item">
            <a routerLink="/support-tickets">Support Tickets</a>
        </li>
        <li class="breadcrumb-item" aria-current="page">
            <a routerLink="/support-tickets/{{supportTicketID}}">Support Ticket #{{supportTicketID}}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
            Edit Ticket
        </li>
    </ol>
</nav>

<div class="container" *ngIf="model">
    <app-alert-display></app-alert-display>
    <div class="row mt-2">
        <div class="col-12">
            <h2 class="d-inline-block">Edit Support Ticket</h2>
        </div>

    </div>

    <div class="row mt-2">
        <div class="col-12">
            <p>Edit support ticket using the form below
            </p>
        </div>
    </div>

    <form (ngSubmit)="onSubmit(editSupportTicketForm)" #editSupportTicketForm="ngForm" class="container">

        <zybach-support-ticket-upsert #supportTicketUpsertForm [(model)]="model" [creatorUser]="creatorUser">
        </zybach-support-ticket-upsert >

        <div class="col-12 text-end mt-3 me-3">
            <div class="col-sm text-start float-end">
                <i class="fas fa-bolt"></i> Required field
            </div>
            <button type="submit" class="btn btn-zybach me-1">
                <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
                Save
            </button>
            <a class="btn btn-secondary ms-1"
                routerLink="..">
                Cancel
            </a>
        </div>

    </form>

</div>




