<div class="container" *ngIf="!wellPumpingSummaries else wellPumpingSummariesList">
    <div class="row">
        <div class="col text-center">
            <span class="fas fa-spinner loading-spinner"></span>
            <p>Loading...</p>
        </div>
    </div>
</div>
<ng-template #wellPumpingSummariesList>
    <div class="container mt-3">
        <div class="row">
            <div class="col">
                <h2 class="d-inline-block">Well Pumping Summary</h2>
                <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
            </div>
        </div>

        <div class="row mb-4 align-items-end">
            <div class="input-group col-sm col-12">
              <span class="fw-bold mt-1">Start Date:</span>
              <div class="input-group">
                <input class="form-control" name="d1" ngbDatepicker #d1="ngbDatepicker" placeholder="yyyy-mm-dd" [(ngModel)]="startDate" />
                <div class="input-group-append">
                  <button class="btn btn-zybach" (click)="d1.toggle()"
                    type="button"><span class="far fa-calendar me-2 col-1"> </span></button>
                </div>
              </div>
            </div>
            <div class="input-group col-sm col-12">
              <span class="fw-bold mt-1">End Date:</span>
              <div class="input-group">
                <input class="form-control" name="d2" ngbDatepicker #d2="ngbDatepicker" placeholder="yyyy-mm-dd" [(ngModel)]="endDate" />
                <div class="input-group-append">
                  <button class="btn btn-zybach" (click)="d2.toggle()"
                    type="button"><span class="far fa-calendar me-2 col-1"> </span></button>
                </div>
              </div>
            </div>
            <div class="col-auto mt-2">
                <button class="btn btn-zybach" (click)="updateWellPumpingSummaries()">Update</button>
            </div>
        </div>
        <div class="row my-2 align-items-end">
            <div class="col">
                <div class="btn-group btn-group-toggle">
                    <label class="btn btn-toggle btn-sm btn-zybach" [class.active]="unitsShown == 'gal'">
                      <input type="radio" name="units" id="gal" (click)="toggleUnitsShown('gal')"> Volume (gal)
                    </label>
                    <label class="btn btn-toggle btn-sm btn-zybach" [class.active]="unitsShown == 'in'" style="width:100px">
                      <input type="radio" name="units" id="in" (click)="toggleUnitsShown('in')"> Depth (in)
                    </label>
                </div>
            </div>
            <div class="col-auto ms-auto">
                <button class="btn btn-secondary btn-md" style="cursor: pointer;" (click)="downloadCsv()"
                    ngbTooltip="Download as CSV" [placement]="['bottom', 'right', 'left', 'auto']"><i class="fas fa-download"></i></button>
                <fresca-clear-grid-filters-button [grid]="wellPumpingSummariesGrid"></fresca-clear-grid-filters-button>
            </div>
        </div>
        <ag-grid-angular #wellPumpingSummariesGrid style="width: 100%; height: 500px;" class="ag-theme-balham" 
            [rowData]="wellPumpingSummaries" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
            [pagination]="true" [rowSelection]="'single'" [paginationPageSize]="100">
        </ag-grid-angular>
    </div>
</ng-template>
