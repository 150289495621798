<div class="breadcrumb-container">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb p-3">
            <li class="breadcrumb-item">
                <a routerLink="/">Home</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">OpenET Integration</li>
        </ol>
    </nav>
</div>
<div class="container mt-sm-4">
    <ng-container *ngIf="!loadingPage; else loadingBlock">
        <app-alert-display></app-alert-display>
        
        <div class="row mb-2">
            <div class="col">
                <h2 class="d-inline-block">OpenET Integration</h2>

                <div class="mt-2">
                    <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        OpenET Sync Status By Month
                    </div>
                    <div class="card-body">
                        <div class="row mb-2">
                            <div class="col-auto ms-auto">
                                <fresca-clear-grid-filters-button [grid]="openETGrid" classList="me-2"></fresca-clear-grid-filters-button>
                                <button class="btn btn-secondary btn-md" style="cursor: pointer;" (click)="exportToCsv()"
                                    ngbTooltip="Download as CSV" [placement]="['bottom', 'right', 'left', 'auto']"><i class="fas fa-download"></i>
                                </button>
                            </div>
                        </div>
                        <ag-grid-angular #openETGrid style="width: 100%; height: 800px" class="ag-theme-balham" [rowData]="openETSyncs" 
                            [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [pagination]="true" [paginationPageSize]="100" 
                            [suppressMenuHide]="true">
                        </ag-grid-angular>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #loadingBlock>
    <div class="text-center">
        <i class="fa fa-spinner loading-spinner"></i> Loading
    </div>
</ng-template>