<div class="container navCaretMargin">
    <app-alert-display></app-alert-display>
    <div class="row">
        <div class="col-12 mb-2">
            <h2 class="d-inline-block">Water Quality Report</h2>
            <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
        </div>
    </div>
    <div class="row">
        <div class="container col-12">
            <div class="row ms-2 mb-2">
                <div class="col">
                    <button (click)="generateReport()" [disabled]="isLoadingSubmit" class="btn btn-zybach btn-md float-end">
                        <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
                        Generate Water Quality Report
                    </button>

                    <a class="btn btn-secondary btn-md float-end me-2" style="cursor: pointer;" (click)="exportToCsv()" [placement]="['bottom', 'right', 'left', 'auto']"
                        ngbTooltip="Download Report as CSV"><i class="fas fa-download"></i></a>

                    <fresca-clear-grid-filters-button class="float-end me-2" [grid]="wellsGrid"></fresca-clear-grid-filters-button>
                </div>
            </div>
            <div class="table-responsive">
                <ag-grid-angular #wellsGrid style="width: 100%; height: 800px;" class="ag-theme-balham" 
                    [rowData]="wells" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" rowSelection="multiple"
                    [pagination]="true" [suppressMenuHide]="true" [paginationPageSize]="100" (gridReady)="onGridReady($event)"
                    (selectionChanged)="onSelectionChanged()" (filterChanged)="onFilterChanged()">
                </ag-grid-angular>
                <div><span>{{ selectedWellsCount }} of {{ rowsDisplayedCount }} Selected</span></div>
            </div>
        </div>
    </div>
</div>

