<nav aria-label="breadcrumb">
    <ol class="breadcrumb p-3">
        <li class="breadcrumb-item">
            <a routerLink="/water-quality-inspections">Water Quality Inspections</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
            Add Inspection
        </li>
    </ol>
</nav>

<div class="container" *ngIf="inspection">
    <app-alert-display></app-alert-display>
    <div class="row mt-2">
        <div class="col-12">
            <h2 class="d-inline-block">Add Inspection</h2>
        </div>

    </div>

    <div class="row mt-2">
        <div class="col-12">
            <p>Add a water quality inspection record using the form below
            </p>
        </div>
    </div>

    <form (ngSubmit)="onSubmit(addWaterQualityInspectionForm)" #addWaterQualityInspectionForm="ngForm" class="container">

        <zybach-water-quality-inspection-upsert #inspectionUpsertForm (isFormValid)="isInspectionUpsertFormValid($event)"
        [(inspection)]="inspection">
        </zybach-water-quality-inspection-upsert >

        <div class="col-12 text-end mt-3 me-3">
            <div class="col-sm text-start">
                <i class="fas fa-bolt"></i> Required field
            </div>
            <div class="float-end">
                <button type="submit" class="btn btn-zybach me-1"
                    [disabled]="isFormValid(addWaterQualityInspectionForm)">
                    <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
                    Save
                </button>
                <a class="btn btn-secondary ms-1"
                    routerLink="..">
                    Cancel
                </a>
            </div>
        </div>

    </form>

</div>

