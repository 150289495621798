<nav aria-label="breadcrumb">
    <ol class="breadcrumb p-3">
        <li class="breadcrumb-item active" aria-current="page">Robust Review Scenario</li>
    </ol>
</nav>
<div class="container">
    <app-alert-display></app-alert-display>
    <h2 class="d-inline-block">Robust Review Scenario</h2>
    <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
    
    <div class="row">
        <div class="col-12 mb-1">
            <button class="btn btn-zybach float-end" (click)="getRobustReviewScenarioJson()" [disabled]="fileDownloading">Download Robust
                Review Scenario .json <span *ngIf="fileDownloading" class="fas fa-spinner loading-spinner"></span> </button>
        </div>
        <div class="col-12 mb-1">
            <fresca-clear-grid-filters-button class="me-3" [grid]="robustReviewScenarioGETRunHistoryGrid">
            </fresca-clear-grid-filters-button>
            <button class="btn btn-zybach float-end" (click)="triggerNewRobustReviewScenarioRun()" [disabled]="!canCreateNewRun() || fileDownloading"> <span *ngIf="newRunTriggered" class="fas fa-spinner loading-spinner"></span><span class="fas fa-plus"></span> New Robust Review Scenario
                Action</button>
        </div>
        <div class="col-12 table table-responsive">
            <ag-grid-angular #robustReviewScenarioGETRunHistoryGrid style="width: 100%; height: 500px;"
                class="ag-theme-balham" [rowData]="robustReviewScenarioGETRunHistories" [columnDefs]="columnDefs"
                [pagination]="true" [suppressMenuHide]="true" [paginationPageSize]="100"
                (firstDataRendered)="onFirstDataRendered($event)" [overlayLoadingTemplate]="overlayLoadingTemplate">
            </ag-grid-angular>
        </div>
    </div>
</div>